<template>
  
  <v-container fluid fill-height fill-width class="justify-center" v-if="ready">

    <v-card class="login-box pb-5 pl-5 pr-5" :loading="loading" outlined>
      <v-card-title class="d-flex flex-column justify-space-between align-center mt-5">
        <v-img src="@/assets/img/logo.png" width="40%" />
        <span class="text-h5 font-weight-light my-3">{{ $t('Register') }}</span>
      </v-card-title>
      <v-card-text>
        <v-form @keyup.native.enter="handleSubmit">
          <v-col cols="12" sm="12" md="12" lg="12" xl="12">
            <v-text-field
                v-model="username"
                append-icon="mdi-account"
                :label="$t('Username')"
                placeholder=" "
                persistent-placeholder
                type="text" outlined
                
                :error="this.submitted && !this.username">
            </v-text-field>
            <v-text-field
                v-model="mail"
                append-icon="mdi-email"
                :label="$t('Mail')"
                placeholder=" "
                persistent-placeholder
                type="mail" outlined
                
                :error="this.inputMailError"
                :error-messages="inputMailError ? 'Tiene que tener el siguiente formato: name@mail.com' : ''">
            </v-text-field>
            <v-text-field
                v-model="password"
                append-icon="mdi-lock"
                :label="$t('Password')"
                placeholder=" " outlined
                persistent-placeholder
                type="password"
                :error="this.inputPasswordError"
                :error-messages="inputPasswordError ? 'La Contraseña tiene que contener un caracter especial, un numero y tener mas de 8 caracteres' : ''"
                >
            </v-text-field>
            <v-text-field
                v-model="confirmPassword"
                append-icon="mdi-lock-plus"
                :label="$t('Confirm Password')"
                placeholder=" " outlined
                persistent-placeholder
                type="password"
                :error="this.confirmPasswordError"
                :error-messages="confirmPasswordError ? 'Las contraseñas no coinciden' : ''"
                >
            </v-text-field>
            <v-text-field
                v-model="token"
                
                :label="$t('Organization Token')"
                placeholder=" " outlined
                persistent-placeholder
                :disabled="user_organization != ''"
                :error="this.submitted && !this.token"
                >
            </v-text-field>
            <v-text-field outlined v-if="this.user_organization"
                :label="$t('Organization')" 
                v-model="this.user_organization" 
                disabled
                ></v-text-field>
                
          </v-col>
        </v-form>
      </v-card-text>
      <v-card-actions class="mb-5">
        <v-btn text color="primary lighten-1" :to="{ path: '/login' }">
          {{ $t('Log in') }}
        </v-btn>
        <v-spacer/>
        <v-btn color="primary" :disabled="loading" @click="handleSubmit">
          {{ $t('Register') }}
        </v-btn>
      </v-card-actions>
      <!-- <div class="text-right">
        <a class="text-body-2" small @click="informative = true; disclaimer=true;">{{ $t("Privacy Policy") }}</a>
      </div> -->
    </v-card>
    
  </v-container>
</template>

<script>
export default {
    name: 'RegisterView',
    data () {
        return {
            username: '',
            mail: '',
            password: '',
            confirmPassword:'',
            user_organization:'',
            ready: false,
            loading: false,
            submitted: false,
            disclaimer:false,
            informative:false,
            confirmPasswordError:false,
            inputMailError:false,
            inputPasswordError:false,
            token:'',
        }
    },
    mounted() {
    //   if (this.$store.getters["auth/loggedIn"]) {
    //     if (this.$route.query.next) {
    //       this.$router.push(this.$route.query.next);
    //     } else {
    //       this.$store.dispatch("auth/logout");
    //     }
    //   }
    this.token = this.$route.query.token; 
    if (this.token){
      this.getTokenOrganization()
    }
    // this.fetchOrganizations()
    this.ready = true;
    },
    watch: {
    confirmPassword() {
        this.checkPasswordMatch();
      },
      
    },
    methods: {
      async getTokenOrganization(handleSubmit=false){
          this.$apiRequest('get', 'organization-token/?token='+this.token, {
            headers: {
                'Authorization': ' '
              }
            },[404]).then((res) => {
              console.log(res,"res")
              if (res.status == 200){
                console.log("organizationToken",res)
                this.user_organization = res.data
                if (handleSubmit){
                  this.handleSubmit()
                }
              }
            }).catch((err)=>{
              if (err.response.status == 404){
                this.$store.dispatch('alert/alert', {
                  type: "warning",
                  message: this.$t("Token did not match any Organization")
                });
              }
            })
      },
      validateMail() {
        this.inputMailError = this.submitted && (!this.mail.includes('@') || !this.mail.includes('.'));
      },
      validatePasswordInput() {
        this.inputPasswordError = this.submitted && ( this.password.length <= 8 
        || !/[!@#$%^&*(),.?":{}|<>]/.test(this.password) 
        || !/[0-9]/.test(this.password) );
      },
      checkPasswordMatch() {
      this.confirmPasswordError = this.submitted && (this.password !== this.confirmPassword);
    },
      handleSubmit() {
        this.informative = false,
        this.submitted = true;
        let {username, mail, password, user_organization } = this;
        this.checkPasswordMatch()
        this.validateMail()
        this.validatePasswordInput()
        if(this.user_organization=='' && !!this.token ){
          this.getTokenOrganization(true)
        }
        if (username && mail && password && !this.confirmPasswordError && user_organization && !this.inputPasswordError &&!this.inputMailError) {

          this.loading = true;
          console.log("user_organizaiton", user_organization)
          this.$store.dispatch(
                  'auth/register',
                  {username, mail, password, organization: [user_organization.replace(/[(),&]/g, ''), 'usr'] }
                  ).then((response) => {
                    this.loading = false;     
                    if (response.status == 201) {
                      this.$store.dispatch('alert/alert', {
                      type: "info",
                      message: "Se ha enviado un mensaje al correo para confirmar la cuenta",
                      timeout: 18000
                      });
                      
                    }
                    else if (response.status == 500 && response.data.includes("entryAlreadyExists")){
                      
                      this.$store.dispatch('alert/alert', {
                      type: "warning",
                      message: this.$t("Error User Already Exists")
                      });
                    }
                  }).catch(() =>{} )


        }
      },
    }
};
</script>

<style scoped>
.login-box {
  min-width: 200px;
  width: 400px;
}
</style>