import Vue from 'vue'
import Router from 'vue-router'
import { store } from '../store'

import Home from '../views/Home.vue'
import OrgProposals from '../views/OrgProposals.vue'
import DtoManagement from '../views/DtoManagement.vue'
import DtoEndorsement from '../views/DtoEndorsement.vue'
import ETC from '../views/ETC.vue'
import Audit from '../views/Audit.vue'
import Monitoring from '../views/Monitoring.vue'
import ProposalDetails from '../views/ProposalDetails.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import PageNotFound from '../views/PageNotFound.vue'
import Dev from '../views/Dev.vue'
import ConfirmMail from '../views/ConfirmMail.vue'
import ResetPassword from '../views/ResetPassword.vue'
import UserProfile from '../views/UserProfile.vue'

Vue.use(Router)

export const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/proposals-list',
      name: 'proposals-list',
      component: OrgProposals,
    },
    {
      path: '/dto-management',
      name: 'dto-management',
      component: DtoManagement,
    },
    {
      path: '/dto-endorsement',
      name: 'dto-endorsement',
      component: DtoEndorsement,
    },
    {
      path: '/etc',
      name: 'etc',
      component: ETC,
    },
    {
      path: '/audit',
      name: 'audit',
      component: Audit,
    },
    {
      path: '/monitoring',
      name: 'monitoring',
      component: Monitoring,
    },
    {
      path: '/proposals/:id',
      name: 'proposal-details',
      component: ProposalDetails,
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/register',
      name: 'register',
      component: Register
    },
    { 
      path: "*",
      name: 'notfound', 
      component: PageNotFound
    },
    {
      path: '/dev',
      name: 'dev',
      component: Dev,
    },
    {
      path: '/confirm-mail',
      name: 'confirm-mail',
      component: ConfirmMail,
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: ResetPassword,
    },
    {
      path: '/profile',
      name: 'user-profile',
      component: UserProfile,
    },
  ]
});

router.beforeEach((to, from, next) => {
  let publicPages = ['/login', '/register','/confirm-mail/','/reset-password/'];
  let authRequired = !publicPages.includes(to.path);
  let loggedIn = store.getters["auth/loggedIn"];
  if (authRequired) {
    if(!loggedIn) {
      return next({name: 'login', query: {next: to.fullPath, ...to.query}});
    }
  }
  return next();
})
