<template>
  <v-dialog v-model="dialog" width="1100">
    <template #activator="{ on }">
      <slot name="activator" :on="on" />
    </template>
    <v-card :loading="loading">
      <v-card-title class="d-flex justify-space-between">
        <span class="pa-5">{{ $t("New observing run") }}</span>
        <v-card-actions class="pr-5">
          <v-chip v-if="etcOutcome" label outlined class="mx-1" color="secondary">{{ $t('Estimated date of delivery') }}: {{ $d(estimatedDate(), 'short') }}</v-chip>
          <v-chip v-if="etcOutcome" label outlined class="mx-1" color="primary">{{ $t('Total exposure time') }}: {{ formatTime(totalRunTime) }}</v-chip>
        </v-card-actions>
      </v-card-title>
      <v-stepper v-model="step" :elevation="0">
        <v-stepper-header class="mb-3 elevation-0">
          <v-stepper-step :editable="step > 1" :complete="step > 1" edit-icon="$complete" step="1">{{ $t('Telescope Model') }}</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :editable="step > 1" :complete="step > 1" edit-icon="$complete" step="1">{{ $t('Camera Model') }}</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :editable="step > 2" :complete="step > 2" edit-icon="$complete" step="2">{{ $t('Target') }}</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :editable="step > 3" :complete="step > 3" edit-icon="$complete" step="3">{{ $t('Observing block definition') }}</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :editable="step > 4" :complete="step > 4" edit-icon="$complete" step="4" >{{ $t('Repetitions') }}</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :editable="step > 5" :complete="step > 5" edit-icon="$complete" step="5">{{ $t('Summary') }}</v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="2"> 
            <div v-if="!targetManualInput">
              <div style="display: flex; align-items: center;">
              <p class="text-body-2 text--secondary">
                {{ $t('Search for your target by name.') }}
                <a @click="targetManualInput = true; form.moving_target=false" class="highlighted-link">
                  {{ $t("Otherwise, you can input its coordinates manually (only for not moving targets).") }}
                </a>
              </p>
              <v-checkbox
                class="p-3 mb-3 ml-2"
                v-model="targetManualInput"
                color="primary"
                off-icon="mdi-eye-off"
                on-icon="mdi-eye"
              ></v-checkbox> 
            </div>
              <div class="d-flex">
                <v-text-field class="mb-5"
                  :label="$t('Search')"
                  outlined v-model="targetSearch" hide-details
                  :placeholder="$t('Search by name')"
                  @keyup.enter="searchSpecificTarget(targetSearch) " 
                  v-if=" placeHolderOption == defaultPlaceholderText"
                />        
                <v-select
                  v-if="placeHolderOption != defaultPlaceholderText"
                  class="mb-5" outlined 
                  v-model="selectedOption"
                  :placeholder="placeHolderOption"
                  hint="Ambiguos Target Please select One"
                  persistent-hint
                  :items="targetOptionsNames"
                  :menu="true" />
                <v-btn color="primary" x-large text @click="(placeHolderOption == defaultPlaceholderText) ? searchSpecificTarget(targetSearch) : searchSpecificTargetFromOptions(selectedOption)">
                  {{ placeHolderOption == defaultPlaceholderText? $t("Search") :  $t("Select") }}
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </div>
              <v-card v-show="!!form.target_name" color="primary lighten-5" elevation="0" class="mb-5">
                <v-card-title class="text-overline">{{ $t('Object found') }}</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field :label="$t('Name')" outlined v-model="form.target_name" hide-details readonly />
                    </v-col>
                  </v-row>
                  <p class="text-body-2 text--secondary mt-5" v-if="form.moving_target">
                    {{ $t('This is a moving target found in JPL Horizons. The coordinates and magnitude will be queried for the actual observation time. At this very moment, these values are the ones below:') }}
                  </p>
                  <v-row>
                    <v-col cols="4">
                      <sexagesimal-field outlined :label="$t('Right ascension')" v-model="form.ra" :hours="true" :readonly="form.moving_target" />
                    </v-col>
                    <v-col cols="4">
                      <sexagesimal-field outlined :label="$t('Declination')" v-model="form.dec" :readonly="form.moving_target" />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field outlined :label="$t('Magnitude (V)')" v-model="form.mag" type="number" :readonly="form.moving_target" />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
            <div v-else>
              <p class="text-body-2 text--secondary">
                {{ $t('Input the name, coordinates and magnitude of your target. You can only input manually fixed targets.') }}
                <a @click="targetManualInput = false; form.target_name = null">
                  {{ $t("If you need to target a moving object, please use our search engine.") }}</a>
              </p>
              <v-row>
                <v-col cols="12">
                  <v-text-field :label="$t('Name')" outlined v-model="form.target_name" hide-details/>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <sexagesimal-field outlined ref="sgfield" :label="$t('Right ascension')" v-model="form.ra" :hours="true" />
                </v-col>
                <v-col cols="4">
                  <sexagesimal-field outlined :label="$t('Declination')" v-model="form.dec" />
                </v-col>
                <v-col cols="4">
                  <v-text-field outlined :label="$t('Magnitude (V)')" v-model="form.mag" type="number" />
                </v-col>
              </v-row>
            </div>
            <v-btn v-show="!!form.ra & !!form.dec & !!form.target_name" text x-small class="mb-3" color="primary" @click="showMosaicOptions = !showMosaicOptions">
                <span v-if="!showMosaicOptions">{{ $t("Create Mosaic") }}</span>
                <span v-else>{{ $t("Hide mosaic options") }}</span>
                <v-icon class="mx-2" small>{{ showMosaicOptions ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
              <v-expand-transition>
                <div v-show="showMosaicOptions ">
                  <div class="d-flex justify-center">
                    <v-card class="mb-3 mr-1 " color="primary lighten-3" elevation="0" >
                      <v-card-title class="text-overline pb-0">{{ $t('Mosaic Covered Area') }}</v-card-title>
                      <v-card-text>
                        <p class="text-caption">{{ $t('Define the dimensions of the total region to be covered by the mosaic, which will be centered around the given coordinates') }}</p>
                        <v-row >
                          <v-col cols="6">
                            <v-text-field outlined :label="$t('Right Ascension')" suffix="º" v-model="formMosaic.area_ra" hide-details />
                          </v-col>
                          <v-col cols="6">
                            <v-text-field outlined :label="$t('Declination')" suffix="º"  v-model="formMosaic.area_dec"  hide-details />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                    <v-card class="mb-3 mx-1 flex-grow-1" color="primary lighten-3" elevation="0">
                      <v-card-title class="text-overline pb-0">{{ $t('Mosaic Overlapping') }}</v-card-title>
                      <v-card-text>
                        <p class="text-caption">{{ $t("Define the degree of overlap between each image.") }}</p>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field outlined :label="$t('Percentage')" suffix="%" v-model="formMosaic.overlapping_percentage" type="number" hide-details />
                          </v-col>
                        </v-row>
                      </v-card-text>
                       
                    </v-card>
                    
                  </div>

                  <v-btn @click="previewMosaic"  style="float:right" >{{ $t("Preview Mosaic") }}</v-btn>
                  <div class="div-mosaic" >
                  <v-progress-circular color="primary lighten-1"   style="position: absolute;margin-left: auto; margin-right: auto; left: 0; right: 0; text-align: center;" indeterminate  v-if="mosaicPreviewIsLoading"></v-progress-circular> 
                  <img v-if="showMosaic" :src="defaulturl+mosaic_url" @load="mosaicImageLoaded" alt="Image Not Found"   v-show="showMosaic & !mosaicPreviewIsLoading">
                  </div>     
                </div>
              </v-expand-transition>
            <v-card-actions class="mt-5">
              <v-spacer/>
              <v-btn @click="step -= 1; reset('step2')">{{ $t("Back") }}</v-btn>
              <v-btn color="primary"
                :disabled="!step2Ready"
                @click="showMosaicOptions && obtainMosaicObservingRunsCoordinates(); step = parseInt(step) + 1;  ">{{ $t("Next") }}</v-btn>
              <v-btn color="secondary" v-if="showMosaicOptions" 
                @click=" createMosaic=false; showMosaicOptions=false;">{{ $t("Cancel Mosaic") }}</v-btn>
            </v-card-actions>
          </v-stepper-content>
          <v-stepper-content step="1"> 
            <p class="text-body-2 text--secondary">
              {{ $t('Select the equipment you want to use. Note that options may be unavailable because of the block definition') }}
            </p>
            <v-row v-if="eq">
              <v-col cols="6">
                <v-select outlined :label="$t('Telescope Model')" v-model="form.telescope_model" :items="Object.keys(this.eq.correspondence)"/>
                <v-list lines="one" class="p-3" >
                    <v-list-item
                      v-for="(value, key) in this.eq.telescope_models.filter(telescope => telescope.model === form.telescope_model)[0]"
                      :key="key"
                    >
                      <p >{{ formatKey(key) }}: {{ value }} {{ key === 'focal_distance' || key ==='diameter' ? 'mm' : key === 'min_elevation' ? 'deg' : '' }}</p>
                    </v-list-item>
                    
                  
                </v-list>
              </v-col>
              <v-col cols="6">
                 
                  <v-select outlined :label="$t('Camera Model')" v-model="form.camera_model" :items="getCameraModel()" :disabled="!form.telescope_model"/>
                  
                  <v-list v-if="form.camera_model">
                    <v-list-item>
                      <p> Num Pixels : {{ this.eq.camera_models.find(model => Object.keys(model)[0] === form.camera_model)[form.camera_model]["num_pixels_x"] }} x {{ this.eq.camera_models.find(model => Object.keys(model)[0] === form.camera_model)[form.camera_model]["num_pixels_y"] }}</p> 
                    </v-list-item>
                     <v-list-item>
                      <p> Minimum Exposure Time : {{ this.eq.camera_models.find(model => Object.keys(model)[0] === form.camera_model)[form.camera_model]["min_exposure_time"] }} s</p>
                    </v-list-item>
                    <v-list-item
                      v-for="(value, key) in calculateFov() "
                      :key="key">
                      <p>{{ formatKey(key) }}: {{ value }}  {{ key === 'Focal Length'  ? 'mm' : key === 'Plate Scale' ? 'arcsec/pixel' : key === 'Field Of View'? 'º' : '' }}</p>
                    </v-list-item>
                  </v-list>

              </v-col>
            </v-row>
            <v-card-actions class="mt-5">
              <v-spacer/>
              <v-btn color="primary"
                :disabled="!step1Ready"
                @click="step = parseInt(step) + 1">{{ $t("Next") }}</v-btn>
            </v-card-actions>
          </v-stepper-content>
          <v-stepper-content step="3">
            <p class="text-body-2 text--secondary">
              {{ $t('The observing block you define here is the indivisible unit of observation that will be repeated over the time. You may now define the lines that will conform this block, setting the filter and the desired SNR.') }}
            </p>
            <v-form>
              <v-alert class="pa-0 mt-5 mr-3" :border="'left'" colored-border color="primary" elevation="2">
                <v-simple-table>
                  <thead>
                    <tr>
                      <th class="text-center">{{ $t('Filter') }}</th>
                      <th class="text-center">{{ $t('SNR') }}</th>
                      <th class="text-center" style="min-width: 150px;">{{ $t('Estimated ET') }}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <v-slide-y-transition group tag="tbody">
                    <tr v-for="line, j in form.lines" :key="j">
                      <td>
                        <v-select dense solo hide-details :label="$t('Filter')" v-model="line.filter"
                          :items="getFilter()" item-text="name" item-value="id" />
                      </td>
                      <td><v-text-field dense solo hide-details :label="$t('SNR')" v-model="line.snr" type="number" /></td>
                      <td class="text-center"><v-chip v-if="etcOutcome && etcOutcome.lines_times[j]" color="primary" x-small>{{ formatTime(etcOutcome.lines_times[j]) }}</v-chip></td>
                      <td><v-btn icon small @click="form.lines.splice(j, 1)" :title="$t('Remove line')"><v-icon small>mdi-delete</v-icon></v-btn></td>
                    </tr>
                  </v-slide-y-transition>
                  <tfoot class="text-right">
                    <tr><td colspan="4" class="text-right">
                      <v-btn small color="primary" text @click="addLine()">{{ $t('Add another line') }}</v-btn>
                    </td></tr>
                  </tfoot>
                </v-simple-table>
              </v-alert>
              <v-btn text x-small class="mb-3" color="primary" @click="showAdvancedOptions = !showAdvancedOptions">
                <span v-if="!showAdvancedOptions">{{ $t("Advanced options") }}</span>
                <span v-else>{{ $t("Hide advanced options") }}</span>
                <v-icon class="mx-2" small>{{ showAdvancedOptions ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
              <v-expand-transition>
                <div v-show="showAdvancedOptions">
                  <div class="d-flex">
                    <v-card class="mb-3 mr-1" color="primary lighten-5" elevation="0">
                      <v-card-title class="text-overline pb-0">{{ $t('Altitude constraints') }}</v-card-title>
                      <v-card-text>
                        <p class="text-caption">{{ $t('Altitude constrain description') }}</p>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field outlined :label="$t('Min altitude')" suffix="º" :rules="maxAltitudeRules" v-model="form.min_altitude" type="number" hide-details />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                    <v-card class="mb-3 mx-1" color="primary lighten-5" elevation="0">
                      <v-card-title class="text-overline pb-0">{{ $t('Exposure time constraints') }}</v-card-title>
                      <v-card-text>
                        <p class="text-caption">{{ $t("Set a maximum for the exposure time in each observing line. Observing blocks will only be scheduled in conditions where the desired SNR can be achieved in less than this time.") }}</p>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field outlined :label="$t('Max exposure time')" suffix="s" v-model="form.max_exposure_time" type="number" hide-details />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                    <v-card class="mb-3 mx-1" color="primary lighten-5" elevation="0">
                      <v-card-title class="text-overline pb-0">{{ $t('Sky Brightness constraints') }}</v-card-title>
                      <v-card-text>
                        <p class="text-caption">{{ $t("Set a sky brightness for perform each observing line in this sky conditions. Observing blocks will only be scheduled in conditions where the desired sky brightness can be achieved") }}</p>
                        <v-row>
                          <v-col cols="12">
                            <v-select clearable outlined :label="$t('Sky Brightness')" v-model="form.sky_brightness"
                          :items="['Bright', 'Gray', 'Dark',null]" item-text="name" item-value="id" />
                        </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                    
                  </div>
                </div>
              </v-expand-transition>
            </v-form>
            <v-card-actions class="mt-5">
              <v-spacer/>
              <v-btn @click="step -= 1;">{{ $t("Back") }}</v-btn>
              <v-btn color="primary"
                :disabled="!step3Ready"
                @click="step = parseInt(step) + 1">{{ $t("Next") }}</v-btn>
            </v-card-actions>
          </v-stepper-content>
          <v-stepper-content step="4" >
            <div class="d-flex align-center mt-1">
                <v-radio-group class="mt-0 mx-5" v-model="repetitions" row>
                  <v-radio :label="$t('Fixed number')" value="fixed-number"></v-radio>
                  <v-radio :label="$t('Fixed time')" value="fixed-time"></v-radio>
                </v-radio-group>
                <v-expand-transition hide-on-leave>
                  <v-text-field v-if = "repetitions == 'fixed-number'" @input="changeMaxRepetitionRule" outlined :label="$t('Number of repetitions')" v-model="form.n_rep_block" type="number" />
                  <v-text-field v-else outlined :label="$t('Total exposure time')" @input="changeMaxTimeRule" v-model="form.t_rep_block" type="number" suffix="hs"/>
                </v-expand-transition>
            </div>
            <v-row>
              <v-col cols="12">
                <cadence-field v-model="form.min_cadence" :disabled="repetitions == 'fixed-number' && (form.n_rep_block <= 1)" />
              </v-col>
            </v-row>
            <div class="d-flex justify-center">
              <v-card class="mb-3 ml-1" color="primary lighten-5" min-width="400" elevation="0">
                      <v-card-title class="text-overline pb-0">{{ $t('Date/time constraints') }}</v-card-title>
                      <v-card-text>
                        <p class="text-caption">{{ $t('Include the observation between the given datetimes.') }}</p>
                        <v-row>
                          <v-col cols="6">
                            <v-datetime-picker date-format="yyyy-MM-dd" time-format="HH:mm:ss" :label="$t('Min datetime')" v-model="form.min_time"
                              :text-field-props="{ outlined: true, hideDetails: true }"
                              :date-picker-props="{ noTitle: true, }"
                              :time-picker-props="{ noTitle: true, useSeconds: true, format: '24hr' }"
                            >
                              <template #dateIcon><v-icon>mdi-calendar</v-icon></template>
                              <template #timeIcon><v-icon>mdi-clock</v-icon></template>
                              <template #actions="{ parent }">
                                <v-btn text color="secondary" @click.native="parent.clearHandler">{{ $t('Clear') }}</v-btn>
                                <v-btn text color="primary" @click="parent.okHandler">{{ $t('Ok') }}</v-btn>
                              </template>
                            </v-datetime-picker>
                          </v-col>
                          <v-col cols="6">
                            <v-datetime-picker dark date-format="yyyy-MM-dd" time-format="HH:mm:ss" :label="$t('Max datetime')" v-model="form.max_time"
                              :text-field-props="{ outlined: true, hideDetails: true }"
                              :date-picker-props="{ noTitle: true, }"
                              :time-picker-props="{ noTitle: true, useSeconds: true, format: '24hr', useUtc: false }"
                            >
                              <template #dateIcon><v-icon>mdi-calendar</v-icon></template>
                              <template #timeIcon><v-icon>mdi-clock</v-icon></template>
                              <template #actions="{ parent }">
                                <v-btn text color="secondary" @click.native="parent.clearHandler">{{ $t('Clear') }}</v-btn>
                                <v-btn text color="primary" @click="parent.okHandler">{{ $t('Ok') }}</v-btn>
                              </template>
                            </v-datetime-picker>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                    <v-card class="mb-3 ml-1 flex-grow-1" color="primary lighten-5" min-width="200" elevation="0">
                      <v-card-title class="text-overline pb-0">{{ $t('Minimum Useful Rep/Time') }}</v-card-title>
                      <v-card-text>
                        <p class="text-caption">{{ $t('Minimum number of repetitions or total time to obtain useful data for proposal.') }}</p>
                        <v-row >
                          <v-col v-if="repetitions == 'fixed-number'" >
                            <v-text-field :rules="maxNumberRepetitions" :max=form.n_rep_block  outlined :label="$t('Nº Rep')"  v-model="form.min_useful_rep" hide-details type="number"/>
                          </v-col>
                          <v-col  v-else>
                            <v-text-field :rules="maxNumberTime" :max=form.t_rep_block outlined :label="$t('Time')" suffix="hs"  v-model="form.min_useful_time" type="number" hide-details />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </div>
            <v-card-actions class="mt-5">
              <v-spacer/>
              <v-btn @click="step -= 1">{{ $t("Back") }}</v-btn>
              <v-btn color="primary"
                :disabled="!step4Ready"
                @click="step = parseInt(step) + 1">{{ $t("Next") }}</v-btn>
            </v-card-actions>
          </v-stepper-content>
          <v-stepper-content step="5">
            <v-row>
              <v-col cols="3">
                <v-card color="primary lighten-5" elevation="0">
                  <v-card-title class="text-overline pb-0 justify-center">{{ $t('Name') }}</v-card-title>
                  <v-card-text class="text-center"> {{ form.target_name }}</v-card-text>
                </v-card>
              </v-col>
              <v-col cols="3">
                <v-card color="primary lighten-5" elevation="0">
                  <v-card-title class="text-overline pb-0 justify-center">{{ $t('Ascension') }}</v-card-title>
                  <v-card-text class="text-center"> {{ form.ra|decimal2sexagesimal }} </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="3">
                <v-card color="primary lighten-5" elevation="0">
                  <v-card-title class="text-overline pb-0 justify-center">{{ $t('Declination') }}</v-card-title>
                  <v-card-text class="text-center"> {{ form.dec|decimal2sexagesimal(true) }} </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="3">
                <v-card color="primary lighten-5" elevation="0">
                  <v-card-title class="text-overline pb-0 justify-center">{{ $t('Magnitude') }}</v-card-title>
                  <v-card-text class="text-center"> {{form.mag}} </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-card color="primary lighten-5" elevation="0">
                  <v-card-title class="text-overline pb-0 justify-center">{{ $t('Telescope model') }}</v-card-title>
                  <v-card-text class="text-center"> {{ form.telescope_model}} </v-card-text>
                </v-card>

              </v-col>
              <v-col cols="3">
                <v-card color="primary lighten-5" elevation="0">
                  <v-card-title class="text-overline pb-0 justify-center">{{ $t('Camera model') }}</v-card-title>
                  <v-card-text class="text-center"> {{ form.camera_model}} </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card color="primary lighten-5" elevation="0">
                  <v-card-title class="text-overline text-center pb-0 justify-center">{{ $t('Repetitions') }}</v-card-title>
                  <v-card-text class="text-center">
                    <span v-if="repetitions == 'fixed-number'">{{ form.n_rep_block }} reps</span>
                    <span v-else>{{ form.t_rep_block }} hs</span>
                    <span class="ml-1 font-weight-light font-italic" v-if="form.min_cadence">(Cadence: {{ getCadence() }} )</span>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="9">
                <v-card color="primary lighten-5" elevation="0">
                  <v-card-title class="text-overline text-center pb-0 justify-center">{{ $t('Observing Block') }}</v-card-title>
                  <v-card-text>
                    <v-simple-table class="primary lighten-5" v-if="step3Ready">
                        <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left"> {{ $t('Filter') }} </th>
                                <th class="text-left"> {{ $t('SNR') }} </th>
                                <th class="text-center"> {{ $t('Estimated ET') }} </th>
                                <th class="text-center" v-if="createMosaic"> {{ $t('Mosaic Observing Runs') }} </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="line, j in form.lines" :key="j">
                                <td>{{ line.filter }}</td>
                                <td>{{ line.snr }}</td>
                                <td class="text-center">
                                  <v-chip v-if="etcOutcome.lines_times[j]" color="primary" x-small>{{ formatTime(etcOutcome.lines_times[j]) }}</v-chip>
                                </td>
                                <td class="text-center" v-if="createMosaic"><v-chip  color="secondary" x-small>{{numberOfObservingRuns}}</v-chip></td>
                              </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="3">
                <v-card color="primary lighten-5" elevation="0">
                  <v-card-title class="text-overline text-center pb-0 justify-center">{{ $t('Total exposure time') }}</v-card-title>
                  <v-card-text class="text-center" v-if="etcOutcome">{{ formatTime(totalRunTime) }}</v-card-text>
                </v-card>
                <v-card color="primary lighten-5" elevation="0" style="margin-top: 24px;">
                    <v-card-title class="text-overline text-center pb-0 justify-center">{{ $t('Needed DTOs (Available)') }}</v-card-title>
                    <v-card-text class="text-center" v-if="etcOutcome">
                      <v-chip dark :color="getDtosColor()">
                        {{ calculateEstimatedDtos(totalRunTime) }} ({{innerParseFloat(this.proposal.available_dtos)}})
                      </v-chip>
                    </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-card-actions class="mt-5">
              <v-spacer/>
              <v-btn @click="step -= 1">{{ $t("Back") }}</v-btn>
              <v-btn color="primary"
                @click="submit()">{{ $t("Create") }}</v-btn>
            </v-card-actions>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
  </v-dialog>
</template>

<script>
import CadenceField from '@/components/utils/CadenceField.vue'
import SexagesimalField from '@/components/utils/SexagesimalField.vue'
import {formatter} from '@/helpers/utils.js'

function setInitialState(){
  return {
      step: 1,
      repetitions: "fixed-number",
      showAdvancedOptions: false,
      showMosaicOptions: false,
      showMosaic:false,
      mosaic_url: "",
      mosaicImg: null,
      defaulturl: process.env.NODE_ENV === 'production'?"https://dto.ttt.lightbridges.es/api/":'http://10.0.203.2:7071/api/',
      etcOutcome: null,
      targetManualInput: false,
      eq:null,
      tariffs:null,
      tariff_ptc_margin:null,
      form: {
        target_name: null,
        target_id:null,
        mag: null,
        ra: null,
        dec: null,
        offset_ra: null,
        offset_dec: null,
        moving_target: false,
        f_sky_motion: null,
        n_rep_block: 1,
        t_rep_block: 0,
        min_cadence: 0,
        min_altitude: null,
        max_exposure_time: null,
        sky_brightness:null,
        minimum_rep:null,
        minimum_time:null,
        min_time: null,
        max_time: null,
        min_useful_time: null,
        min_useful_rep:null,
        full_interval: false,
        telescope_model:null,
        camera_model:null,
        lines: [{
          filter: null,
          snr: null,
        }],
        estimated_dtos: 0,
        // locked_dtos:0
      },
      formMosaic: {
        overlapping_percentage:1,
        area_ra:0,
        area_dec:0
      },
      defaultPlaceholderText:"Select Target",
      selectedOption: "",
      placeHolderOption:"Select Target",
      createMosaic: false,
      mosaicObservingRunsCoordinates: [],
      mosaicPreviewIsLoading: false,
      numberOfObservingRuns: 1,
    }
}

export default {
  name: "NewObservingRun",
  props: [ "proposal" ],
  components: { SexagesimalField, CadenceField },
  data: function() {
    return {
      dialog: false,
      loading: false,
      lastFetchTime: null,
      maxAltitudeRules: [ 
      v => ( v && v >= -90 ) || "Min Altitude should be above -90º",
      v => ( v && v <= 90 ) || "Min Altitude should not be above 90º",
      ],
      maxNumberRepetitions: [
        v => { const num = parseInt(v,10)
          return ( v && num <= this.form.n_rep_block ) || "Min Useful Repepetitions should not be above the total number of repetitions"}
      ],
      maxNumberTime:  [
        v => { const num = parseInt(v,10)
          return ( v && num <= this.form.t_rep_block ) || "Min Useful Time should not be above the total time"}
      ],
      targetOptions: [],
      targetOptionsNames: [],
      targetSearch: '',
      default_mag: 21,
      ...setInitialState(),

    }
  },
  computed: {
    etcForm() {
      return {
        target_magnitude: this.form.mag,
        zenithal_distance: 30,
        lines: this.form.lines,
        seeing: 1.3,
        sky_brightness: 20,
        telescope_model: this.form.telescope_model,
        camera_model: this.form.camera_model
      }
    },
    totalRunTime() {
      let totalRunTime = 0
      if (this.repetitions == "fixed-number") {
        totalRunTime = this.etcOutcome.total_time * this.form.n_rep_block;
      } else {
        totalRunTime = this.form.t_rep_block * 3600;
      }
      if(this.createMosaic){
        return totalRunTime * this.numberOfObservingRuns
      }
      else{
        return totalRunTime
      }
    },
    step2Ready() {
      return !!this.form.target_name && (this.form.moving_target || (this.form.ra && this.form.dec && this.form.mag))
    },
    step1Ready() {
      return !!this.form.telescope_model && !!this.form.camera_model 
    },
    step3Ready() {
      return (this.form.lines.length > 0) && !this.form.lines.some(obj => !obj.filter || !obj.snr) && !!this.etcOutcome && !this.loading
    },
    step4Ready() {
      return ((this.repetitions == 'fixed-number') && (this.form.n_rep_block >= 1) && (!this.form.min_useful_rep ||(parseInt(this.form.min_useful_rep, 10) <= this.form.n_rep_block)))
          || ((this.repetitions == 'fixed-time') && !!this.form.t_rep_block && (!this.form.min_useful_time || (parseInt(this.form.min_useful_time,10) <= this.form.t_rep_block)));
    },
    altForm() {
      return {
        ...this.form,
        min_time: this.extractTzOffset(this.form.min_time),
        max_time: this.extractTzOffset(this.form.max_time),
      } 
    },
    
  },
  mounted() {
    this.fetchEquipment();
    this.fetchTariffs();
    
  },
  methods: {
    fetchEquipment() {
      this.loading = true;
      this.$apiRequest('get', 'equipment/').then((res) => {
        this.loading = false;
        this.eq = res.data;
      })
    },
    changeMaxRepetitionRule(){
      this.maxNumberRepetitions=  [
        v => { const num = parseInt(v,10)
          return ( v && num <= this.form.n_rep_block ) || "Min Useful Rep should not be above the total number of repetitions"}
      ]
      if (this.form.min_useful_rep > this.form.n_rep_block) {
        this.form.min_useful_rep = this.form.n_rep_block
      }
    },
    changeMaxTimeRule(){
      this.maxNumberTime=  [
        v => { const num = parseInt(v,10)
          return ( v && num <= this.form.t_rep_block ) || "Min Useful Rep should not be above the total number of repetitions"}
      ]
      if (this.form.min_useful_time > this.form.t_rep_block) {
        this.form.min_useful_time = this.form.t_rep_block
      }
    },

    formatKey(key) {
    let words = key.split('_');
    words = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    return words.join(' ');
    },
    fetchTariffs() {
      this.loading = true;
      this.$apiRequest('get', 'tariffs/').then((res) => {
        this.loading = false;
        this.tariffs = res.data.tariffs;
        this.tariff_ptc_margin = res.data.tariff_ptc_margin;
      })
    },
    reset(step){
      if (step == "step3"){
      this.etcOutcome=null; 
      this.form.lines= [{
          filter: null,
          snr: null,
        }]}
      else if (step =="step2"){
        this.form.target_name = null ;
        this.form.moving_target = false;
        this.form.ra = this.form.dec =this.form.mag = null;
        this.placeHolderOption = this.defaultPlaceholderText;
        this.selectedOption = ""
        this.targetOptions=[]
        this.targetOptionsNames=[]   
      }
      
    },
    genereateMosaicURL(){
      this.mosaic_url= "visualization/?";
      this.mosaic_url+="central_ra=" +this.form.ra *15 + "&" + "central_dec="+this.form.dec +"&" + "camera_model=" + this.form.camera_model +"&"+ "telescope_model=" +  this.form.telescope_model+ "&";  //El endpoint espera ra y dec en grados, decimales
      for (const key in this.formMosaic) {
        if (key == "overlapping_percentage"){
          this.mosaic_url += `${key}=`+(this.formMosaic.overlapping_percentage /100)+"&";
        }
        else{
          this.mosaic_url += `${key}=${this.formMosaic[key]}&`;
        }

      }

      this.mosaic_url = this.mosaic_url.slice(0, -1);
     
    },
    previewMosaic(){
      let previous_mosaic_url = this.mosaic_url
      this.mosaic_url="";
      this.mosaicPreviewIsLoading = true;
      this.genereateMosaicURL()
        if (this.mosaic_url == previous_mosaic_url) {
        this.mosaicPreviewIsLoading= false
      }
      this.showMosaic = true
    },
    mosaicImageLoaded(){
      this.mosaicPreviewIsLoading = false
    },
    calculateFov(){
      let pixel_size = this.eq.camera_models.find(model => Object.keys(model)[0] === this.form.camera_model)[this.form.camera_model]["pixel_size"]
      let num_pixels_x = this.eq.camera_models.find(model => Object.keys(model)[0] === this.form.camera_model)[this.form.camera_model]["num_pixels_x"]
      let num_pixels_y = this.eq.camera_models.find(model => Object.keys(model)[0] === this.form.camera_model)[this.form.camera_model]["num_pixels_y"]
      let focal_reducer = this.eq.camera_models.find(model => Object.keys(model)[0] === this.form.camera_model)[this.form.camera_model]["focal_reducer"]
      let focal_distance = this.eq.telescope_models.filter(telescope => telescope.model === this.form.telescope_model)[0]["focal_distance"]
      let focal_length = focal_distance *focal_reducer 
      let plate_scale = 206.265  / focal_length * pixel_size
      let fov_x = (num_pixels_x * plate_scale /3600 ).toFixed(2)
      let fov_y = (num_pixels_y * plate_scale /3600 ).toFixed(2)
      return { 
        "Plate Scale": plate_scale.toFixed(3),
        "Field Of View": fov_x + " x "+ fov_y,
      }
    },
    
    getCameraModel() {
      if (this.form.telescope_model){
        
        return Object.keys(this.eq.correspondence[this.form.telescope_model])

      } else {
        return null
      }
    },
    getFilter() {
      if (this.form.telescope_model){
        return this.eq.correspondence[this.form.telescope_model][this.form.camera_model]
        
      } else {
        return null
      }
    },
    resetForm: function() {
      Object.assign(this.$data, setInitialState())
    },
    searchByName() { 
      this.loading = true;
      this.form.target_name = ""
        this.$apiRequest('get', 'horizon/', { 
        params: {
          id: this.targetSearch,
        },
      }, [404]).then((response) => {
        if(response.data.code == "300"){
          this.targetOptions = response.data.list.map(item =>({
            id: item.id,
            name: item.name
          }))
          this.targetOptionsNames = this.targetOptions.map(option => option.name)
          this.placeHolderOption = this.targetOptions[0].name
          this.loading = false
        }
        else{
          this.loading = false;
          this.form.target_name = this.targetSearch;
          this.targetManualInput = true;
          this.form.ra = null;
          this.form.dec = null;
          this.form.mag = null;
          this.form.moving_target = false;
          this.$store.dispatch('alert/alert', {
            type: "warning",
            message: this.$t("Cannot find any target by the given name. Please, input the coordinates of your target.")
          });
        }
      })
    },
    searchSpecificTarget(specificTarget,fromOptions=false) {
      this.loading = true;
      this.form.target_name = ""
      this.$apiRequest('get', 'target-lookup/', { 
        params: {
          id: specificTarget,
        },
      }, [404,500]).then((response) => {
        this.loading = false;
        this.form.target_name = isNaN(+specificTarget) ? specificTarget : fromOptions? response.data.name.replace(/\(\d+\)?/g, ''):specificTarget ;
        this.form.ra = response.data.ra;
        this.form.dec = response.data.dec;
        this.form.mag = response.data.mag;
        this.form.moving_target = response.data.source == 'jpl-horizons';
      }).catch(() => {
          this.searchByName()
      })
    },
    searchSpecificTargetFromOptions(targetName){
      let targetID=this.targetOptions.filter(option=> option.name == targetName)[0].id
      this.form.target_id = targetID
      this.searchSpecificTarget(targetID,true)
    },
    extractTzOffset(date) {
      if (!date) {
        return null
      }
      var copy = new Date(date.getTime());
      copy.setUTCFullYear(copy.getFullYear());
      copy.setUTCMonth(copy.getMonth());
      copy.setUTCDate(copy.getDate());
      copy.setUTCHours(copy.getHours());
      return copy;
    },
    submit() {
      this.loading = true;
      let observingRunData;
      let urlAppendix = "";
      if (this.createMosaic){
        urlAppendix= "?mosaic=True"
        observingRunData = this.createObservingRunsFromMosaic()
      } 
      else{
        observingRunData = {
          proposal: this.proposal.id,
          ...this.altForm,
        }
      }
      this.$apiRequest('post', 'observing-runs/' +urlAppendix, { 
        data: observingRunData
  
      }, [400]).then(() => {
        this.$store.dispatch('alert/alert', {
          type: "info",
          message: this.$t("Observing run succesfully created")
        });
        this.loading = false;
        this.dialog = false;
        this.$emit("done");
        this.resetForm()
        this.fetchEquipment()
        this.fetchTariffs()
      }).catch(error => {
        var error_msg = ""
        if (error.response.data[0] == "NOT_ENOUGH_DTOS") {
          error_msg = "There are not enough available DTOs";
        } else {
          error_msg = error.response.data[0];
        }

        this.$store.dispatch('alert/alert', {
                message: error_msg,
                type: "error",
            });
        this.loading = false;
      })
    },
    obtainMosaicObservingRunsCoordinates(){
      this.loading= true
      this.createMosaic=true
      this.genereateMosaicURL()
      this.$apiRequest('get', this.mosaic_url.replace("visualization", "grid-coordinates")).then((res) => {
        this.mosaicObservingRunsCoordinates= res.data
        this.numberOfObservingRuns  = this.mosaicObservingRunsCoordinates.length
        this.loading= false
      })
      
    },
    createObservingRunsFromMosaic(){
      let observingRuns = []
      this.mosaicObservingRunsCoordinates.forEach(([ra_off, dec_off])=>{
         observingRuns.push({
          proposal: this.proposal.id,
          // utilizammos el ra y el dec del objeto base y añadimos el offset
          ...this.altForm,
          offset_ra: ra_off/15,
          offset_dec:dec_off,
        });
      })
      return observingRuns
    },
    addLine() {
      this.form.lines.push({
        filter: null,
        snr: null,   
      })
    },
    estimatedDate() {
      var date = new Date(); // Now
      date.setDate(date.getDate() + 30)
      return date;
    },
    etcFormIsValid() {
      let isValid = true;
      
        if (!this.form.lines) {
          isValid = false;
        } else {
          this.form.lines.forEach((line) => {
            if (!line.snr || !line.filter) {
              isValid = false
            }
          })
        }
      
      return isValid
    },
    formatTime(seconds) {
      if (seconds > 3600) {
        return `${Math.floor(seconds / 3600)}h ${Math.floor((seconds % 3600) / 60)}min ${Math.floor(seconds % 60)}s`
      } else if (seconds > 60) {
        return `${Math.floor(seconds / 60)}min ${Math.floor(seconds % 60)}s`
      } else {
        return `${seconds.toFixed(2)}s`
      }
    },
    getCadence() {
      if (this.form.min_cadence<12){
          return `${this.form.min_cadence} hours`
      } else if (this.form.min_cadence>12 && this.form.min_cadence<7*24-8){
          return `${Math.floor((this.form.min_cadence + 8)/24)} days`
      }else if (this.form.min_cadence<7*24-8 && this.form.min_cadence<24*30-8){
          return `${Math.floor((this.form.min_cadence + 8)/(24*7))} weeks`
      }else if (this.form.min_cadence<24*30-8 && this.form.min_cadence<24*365-8){
          return `${Math.floor((this.form.min_cadence + 8)/(24*30))} months`
      }else if (this.form.min_cadence>24*365-8){
          return `${Math.floor((this.form.min_cadence + 8)/(24*365))} years`
      }
    },
    calculateEstimatedDtos(seconds) {   
      var dto_hour = this.tariffs.find(item => item[0] === this.form.telescope_model)[1];
      var price = dto_hour / 3600  * seconds;
      var margin = price * this.tariff_ptc_margin / 100;
      var total_dtos = (price + margin).toFixed(4)
      var dtos = total_dtos
      if(this.createMosaic){
        dtos = total_dtos / this.numberOfObservingRuns
      }
      
      if(dtos < 0.0001) {
        dtos = 0.0001;
      }
      this.form.estimated_dtos = dtos 
      return total_dtos;
    },
    getDtosColor() {
      if(this.form.estimated_dtos > this.proposal.available_dtos) {
        return "red"
      } else {
        return "green"
      }
    },
    innerParseFloat(float){
      return formatter.format(float)
    }
  },
  watch: {
    createMosaic:{
      handler(){
        this.reset("step3")
      }
    },
    etcForm: {
      deep: true,
      immediate: true,
      handler(newVal) {
        var thisCallTime = this.lastFetchTime = Date.now()
        
        if (this.form.mag == null) {
          this.form.mag = this.default_mag
        } 
        if (this.etcFormIsValid()) {
          this.loading = true;
          
          this.$apiRequest('post', 'etc/', { data: newVal }).then((res) => {
            if (this.lastFetchTime <= thisCallTime) {
              this.etcOutcome = res.data;
            }
            this.$nextTick(() => {
              this.loading = false;
            })
          })
        } else {
          if (this.lastFetchTime <= thisCallTime) {
            this.etcOutcome = null;
          }
        }
      }
    },
    repetitions: function(newVal) {
      if (newVal == 'fixed-time') {
        if (this.form.n_rep_block) {
          this.form.t_rep_block = Math.floor((this.etcOutcome.total_time * this.form.n_rep_block) / 3600);
        }
        this.form.n_rep_block = null;
      } else {
        if (this.form.t_rep_block) {
          this.form.n_rep_block = Math.floor((this.form.t_rep_block * 3600) / this.etcOutcome.total_time);
        }
        this.form.t_rep_block = null;
      }
    },
  }
}
</script>

<style scoped>
.div-mosaic {
  min-height: 30px;
  max-height: 100%;
  max-width:100%;
  text-align: center;
}
img {
  max-height: 100%; /* Establece el ancho máximo de la imagen al ancho del contenedor */
  max-width: 100%;
  /* Permite que la altura se ajuste automáticamente para mantener la relación de aspecto */
  
}
</style>
