<template>
  
  <v-container fluid fill-height fill-width class="justify-center" >

    <v-card class=" pb-5 pl-5 pr-5" :loading="loading" outlined style="width: 400px;">
  <v-card-title class="d-flex flex-column justify-space-between align-center mt-5">
    <v-img src="@/assets/img/logo.png" width="40%" />
    <div style="min-height: 50px;"> <!-- Añade un min-height fijo al contenedor del span -->
      <span class="text-h5 font-weight-light my-3" v-if="!loading">
        {{ $t('Mail confirmation success') }}
      </span>
      <span class="text-h5 font-weight-light my-3" v-if="loading">
        {{ $t('Validating Mail ...') }}
      </span>
    </div>
  </v-card-title>
  
  
  
  <v-card-actions class="mb-5 d-flex justify-center">
    <v-btn color="primary" :disabled="loading" :to="{ path:'/login'}" >
      {{ $t('Sign in') }}
    </v-btn>
  </v-card-actions>
  
  
</v-card>
    
  </v-container>
</template>

<script>
export default {
    name: 'ConfirmMailView',
    data () {
        return {
            mail: '',
            loading: true
           
        }
    },
    mounted() {
        
    const token = this.$route.query.token;
    const mail = this.$route.query.mail;
    const username = this.$route.query.username;

    if (token && mail && username) {
      this.confirmMail(mail,token,username)
        
    }
    
    },
    methods: {
        confirmMail(user_mail,token,username) {
            this.$apiRequest('post', 'confirm-mail/', {
              data: {
                user_mail:user_mail,
                token: token,
                username:username
              },
              headers: {
                'Authorization': ' '
              }
            },[406]).then((res) => {
              console.log(token)
              if (res.status == 202) {
                
                 this.$store.dispatch('alert/alert', {
                      type: "info",
                      message: this.$t("Account successfully activated.")
                      });
                this.loading= false
                // this.$router.push(this.$route.query.next || "/")
              }
             }).catch((error) => {
              if (error.message.includes("406")) {
                this.$store.dispatch('alert/alert', {
                type: "warning",
                message: "Token not valid or expired"
                });
              }
              else{
                this.$store.dispatch('alert/alert', {
                type: "warning",
                message: this.$t(error)
                });
              }
              
                
            //     // console.log(error)
            //     // console.log(error.detail)
            //     // console.log("hola")
            //     // console.log(error.message)

            })
        }  
    }
};
</script>

<style scoped>

</style>