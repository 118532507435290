<template>
    <nav v-if="$store.getters['auth/loggedIn']">
        <v-navigation-drawer app dark color="primary" v-model="drawer" expand-on-hover :mini-variant.sync="mini">
            <v-list color="primary lighten-1">
                <v-list-item>
                    <v-list-item-action class="mr-5">
                        <v-icon>mdi-telescope</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-bold">TTT Project</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list>
                <v-list-item router :to="{ path: '/' }">
                    <v-list-item-action class="mr-5"><v-icon>mdi-home</v-icon></v-list-item-action>
                    <v-list-item-content><v-list-item-title>{{ $t('Home') }}</v-list-item-title></v-list-item-content>
                </v-list-item>
                <v-list-item router :to="{ name: 'proposals-list' }" v-if="$store.getters['auth/isOrganizationAdmin']">
                    <v-list-item-action class="mr-5"><v-icon>mdi-warehouse</v-icon></v-list-item-action>
                    <v-list-item-content><v-list-item-title>{{ $t('Organization proposals') }}</v-list-item-title></v-list-item-content>
                </v-list-item>
                <v-list-item router :to="{ name: 'dto-management' }" v-if="$store.getters['auth/isDtoManager']">
                    <v-list-item-action class="mr-5"><v-icon>mdi-timer-plus-outline</v-icon></v-list-item-action>
                    <v-list-item-content><v-list-item-title>{{ $t('DTO management') }}</v-list-item-title></v-list-item-content>
                </v-list-item>
                <v-list-item router :to="{ name: 'dto-endorsement' }" v-if="$store.getters['auth/isOrganizationAdmin']">
                    <v-list-item-action class="mr-5"><v-icon>mdi-transfer </v-icon></v-list-item-action>
                    <v-list-item-content><v-list-item-title>{{ $t('DTO endorsement') }}</v-list-item-title></v-list-item-content>
                </v-list-item>
                <v-list-item router :to="{ name: 'etc' }">
                    <v-list-item-action class="mr-5"><v-icon>mdi-calculator</v-icon></v-list-item-action>
                    <v-list-item-content><v-list-item-title>{{ $t('ETC') }}</v-list-item-title></v-list-item-content>
                </v-list-item>
                <v-list-item router :to="{ name: 'audit' }">
                    <v-list-item-action class="mr-5"><v-icon>mdi-air-filter</v-icon></v-list-item-action>
                    <v-list-item-content><v-list-item-title>{{ $t('Audit') }}</v-list-item-title></v-list-item-content>
                </v-list-item>
                <v-list-item router :to="{ name: 'monitoring' }" v-if="$store.getters['auth/isAdmin']">
                    <v-list-item-action class="mr-5"><v-icon>mdi-monitor</v-icon></v-list-item-action>
                    <v-list-item-content><v-list-item-title>{{ $t('Monitoring') }}</v-list-item-title></v-list-item-content>
                </v-list-item>
                <v-list-item href="/admin" v-if="$store.getters['auth/isAdmin']">
                    <v-list-item-action class="mr-5"><v-icon>mdi-hammer-screwdriver</v-icon></v-list-item-action>
                    <v-list-item-content><v-list-item-title>{{ $t('Admin') }}</v-list-item-title></v-list-item-content>
                </v-list-item>
                <v-list-item router :to="{ name: 'user-profile' }">
                    <v-list-item-action class="mr-5"><v-icon>mdi-account</v-icon></v-list-item-action>
                    <v-list-item-content><v-list-item-title>{{ $t('Profile') }}</v-list-item-title></v-list-item-content>
                </v-list-item>
                <v-list-item @click="logout">
                    <v-list-item-action class="mr-5"><v-icon>mdi-logout-variant</v-icon></v-list-item-action>
                    <v-list-item-content><v-list-item-title>{{ $t("Log out") }}</v-list-item-title></v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
        <v-app-bar class="hidden-lg-and-up" color="primary lighten-1">
          <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        </v-app-bar>   
    </nav>
</template>


<script>

export default {
    data() {
        return {
            drawer: true,
            mini: true,
        }
    },
    methods: {
        logout () {
            this.$store.dispatch('auth/logout').then(() => {
                this.$router.push("login");
            });
        }
    },
}
</script>
