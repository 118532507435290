import axios from 'axios'
import { apiPath } from '../config'
import i18n from '@/plugins/i18n' 

const development = process.env.NODE_ENV === 'development'
   
export default {
  install(Vue) {
    Vue.prototype.$apiRequest = function(method, url, options={}, throwErrors=[]) {
      return axios({
        method: method,
        url: apiPath + url,
        params: options.params,
        headers: {
          'Authorization': 'Bearer ' + this.$store.state.auth.accessToken,
          ...options.headers,
          'Accept-Language': this.$root.$i18n.locale,
        },
        data: options.data
      }).catch(error => {
        if (error.response) {
          /*
          * The request was made and the server responded with a
          * status code that falls out of the range of 2xx
          */
          if (throwErrors.indexOf(error.response.status) != -1) {
            throw error;
          }
          // Si estamos en producción no mostramos el error exacto 
          if (error.response.status == 400 || error.response.status == 401) {
            // Client bad request
            console.log("client bad request")
            this.$store.dispatch('alert/alert', {
                // message: development? error.response.data: i18n.t("There was an error with the request"),
                message: error.response.data,
                type: "error",
            });
          } else {
            // Other errors
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            console.log("error handling")
            // Si estamos en producción no mostramos el error exacto 
            this.$store.dispatch('alert/alert', {
                message:  development? this.$t("Server Response {code} at {url}", { code: error.response.status, url: error.request.responseURL }): i18n.t("There was an external error"),
                // message:  this.$t("Server Response {code} at {url}", { code: error.response.status, url: error.request.responseURL }),
                type: "error",
            });
          }
        } else if (error.request) {
          /*
              * The request was made but no response was received, `error.request`
              * is an instance of XMLHttpRequest in the browser and an instance
              * of http.ClientRequest in Node.js
              */
          console.log(error.request);
          this.$store.dispatch('alert/alert', {
            message: this.$t("Server did not respond"),
            type: "error",
          });
        } else {
          // Something happened in setting up the request and triggered an Error
          console.error(error.message);                
          this.$store.dispatch('alert/alert', {
            message:  development? this.$t("App Error: {error}", { error: error.message }): i18n.t("Something happened in setting up the request"),
            type: "error",
          });
        }
        throw error
      })
    }
  } 
}
