<template>
  
  <v-container fluid fill-height fill-width class="justify-center" >

    <v-card class="login-box pb-5 pl-5 pr-5" :loading="loading" outlined>
      <v-card-title class="d-flex flex-column justify-space-between align-center mt-5">
        <v-img src="@/assets/img/logo.png" width="40%" />
        <span class="text-h5 font-weight-light my-3">{{ $t('Reset Password') }}</span>
      </v-card-title>
      <v-card-text>
        <v-form @keyup.native.enter="handleSubmit">
          <v-col cols="12" sm="12" md="12" lg="12" xl="12">
            <v-text-field
                v-model="mail"
                append-icon="mdi-account"
                :label="$t('Mail')"
                placeholder=" "
                persistent-placeholder
                type="text" outlined
                disabled>
            </v-text-field>
            <v-text-field
                v-model="password"
                append-icon="mdi-lock"
                :label="$t('Password')"
                placeholder=" "
                persistent-placeholder
                type="password" outlined
                :error="this.inputPasswordError"
                :error-messages="inputPasswordError ? 'La Contraseña tiene que contener un caracter especial, un numero y tener mas de 8 caracteres' : ''"
                >
            </v-text-field>
            <v-text-field
                v-model="confirmPassword"
                append-icon="mdi-lock"
                :label="$t('Confirm Password')"
                placeholder=" " outlined
                persistent-placeholder
                type="password"
                :error="this.inputConfirmPasswordError"
                :error-messages="inputConfirmPasswordError ? 'Las contraseñas no coinciden' : ''">
            </v-text-field>
           
          </v-col>
        </v-form>
      </v-card-text>
      <v-card-actions class="mb-5">
       
        <v-btn color="primary" :disabled="loading" @click="handleSubmit">
          {{ $t('Reset Password') }}
        </v-btn>
      </v-card-actions>
      <div class="text-right">
              <a class="text-body-2" small @click="informative = true; disclaimer=true;">{{ $t("Privacy Policy") }}</a>
            </div>
    </v-card>
    
  </v-container>
</template>

<script>
export default {
    name: 'ResetPasswordView',
    data () {
        return {
            mail: '',
            username:'',
            loading: false,
            submitted: false,
            password:'',
            confirmPassword:'',
            token:'',
            inputPasswordError:false,
            inputConfirmPasswordError:false

           
        }
    },
    
    mounted() {
        
    this.token = this.$route.query.token;
    
    this.mail = this.$route.query.mail;
    this.username = this.$route.query.username;

    if (!this.token || !this.mail || !this.username) {
      console.log('Token ,mail o username no están presentes en la URL.');
    //this.confirmMail(mail,token,password)
      }
    },
     watch: {
    password() {
       this.validatePasswordInput();
    },
    confirmPassword() {
        this.checkPasswordMatch();
      },
      
    },
    methods: {
      validatePasswordInput() {
        this.inputPasswordError = this.submitted && ( this.password.length <= 8 
        || !/[!@#$%^&*(),.?":{}|<>]/.test(this.password) 
        || !/[0-9]/.test(this.password) );
      },
      checkPasswordMatch() {
      this.inputConfirmPasswordError = this.submitted && (this.password !== this.confirmPassword);
    },
        handleSubmit() {
        this.submitted = true;
        let { password, confirmPassword } = this;
        this.validatePasswordInput()
        if (confirmPassword && password) {
          this.loading = true;

          this.$apiRequest('post', 'auth/reset-password/', {
              data: {
                token: this.token,
                password:password,
                user_mail:this.mail,
                username:this.username
              },
              headers: {
                'Authorization': ' '
              }
            }).then((res) => {
              if (res.status == 200){
                this.$store.dispatch('alert/alert', {
                      type: "info",
                      message: this.$t("Password has been successfully reset")
                      });
              }
              this.loading = false;
              
             }).catch((error) => {
              this.loading = false;
              if (error.message.includes("406")) {
                this.$store.dispatch('alert/alert', {
                type: "warning",
                message: "Token not valid or expired"
                });
              }
              else{
                this.$store.dispatch('alert/alert', {
                type: "warning",
                message: this.$t(error)
                });
              }
              
            })
          }
        },
        resetPassword(user_mail,token,password) {
            this.$apiRequest('post', 'reset-password/', {
              data: {
                user_mail:user_mail,
                token: token,
                password:password
              },
              headers: {
                'Authorization': ' '
              }
            }).then(() => {
              
             }).catch((error) => {
              if (error.message.includes("406")) {
                this.$store.dispatch('alert/alert', {
                type: "warning",
                message: "Token not valid or expired"
                });
              }
              else{
                this.$store.dispatch('alert/alert', {
                type: "warning",
                message: this.$t(error)
                });
              }
              
                
            //     // console.log(error)
            //     // console.log(error.detail)
            //     // console.log("hola")
            //     // console.log(error.message)

            })
        }  
    }
};
</script>

<style scoped>

</style>