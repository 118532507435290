<template>
  <v-card :loading="loading" elevation="0">
    <v-card-title>{{ $t('Exposure Time Calculator') }}</v-card-title>
    <v-divider class="mb-3" />
    <v-row>
      <v-col cols="8">
        <v-card-text>
          <v-row class="mt-3">
            <v-col cols="6">
              <v-slider outlined :label="$t('Target magnitude')" min="0" max="30" v-model="form.target_magnitude" thumb-label="always" />
            </v-col>
            <v-col cols="6">
              <v-slider outlined :label="$t('Zenithal distance')" min="0" max="90" v-model="form.zenithal_distance" thumb-label="always">
                <template v-slot:thumb-label="{ value }">
                  {{ value }}º
                </template>
              </v-slider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-select outlined :label="$t('Filter')" v-model="form.lines[0].filter" :items="filterOptions" clearable />
            </v-col>
            <v-col cols="6">
              <v-text-field outlined :label="$t('SNR')" v-model="form.lines[0].snr" type="number" />
            </v-col>
          </v-row>
          <v-card class="my-3" color="primary lighten-5" elevation="0">
            <v-card-title class="text-overline pb-0">{{ $t('Sky conditions') }}</v-card-title>
            <v-card-text>
              <p class="text-caption">{{ $t('When planning, TTT will use a forecast of these values.') }}</p>
              <v-row>
                <v-col cols="6">
                  <v-text-field type="number" outlined :label="$t('Seeing at zenith')" v-model="form.seeing" hide-details />
                </v-col>
                <v-col cols="6">
                  <v-text-field type="number" outlined :label="$t('Sky brightness')" v-model="form.sky_brightness" hide-details />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card class="my-3" color="primary lighten-5" elevation="0">
            <v-card-title class="text-overline pb-0">{{ $t('Instrument') }}</v-card-title>
            <v-card-text>
              <p class="text-caption">{{ $t('TTT automatically chooses the most appropiate instrument to perform the observation based on the selected filters and SNR. However, you can force your observation to be performed with the instrument you would like.') }}</p>
              <v-row>
                <v-col cols="6">
                  <v-select outlined :label="$t('Telescope Model')" v-model="form.telescope_model" :items="telescopeOptions" clearable />
                </v-col>
                <v-col cols="6">
                  <v-select outlined :label="$t('Camera Model')" v-model="form.camera_model" :items="cameraOptions" clearable :disabled="!form.telescope_model"/>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-col>
      <v-col cols="4">
        <v-expand-transition hide-on-leave>
          <v-card v-if="!!outcome" width="400" dark color="primary darken-3" elevation="0">
            <v-card-title>{{ $t('Exposure time') }}</v-card-title>
            <v-card-text class="text-h1">{{ formatTime(outcome.total_time) }}</v-card-text>
          </v-card>
        </v-expand-transition>
        <v-card v-if="!outcome" class="d-flex flex-column align-center justify-center" height="500" elevation="0">
          <v-card-text class="text-center">{{ $t("Fill out filter and SNR lines to get started")}}</v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'ETC',
  data: function() {
    return {
      loading: false,
      lastFetchTime: null,
      equipment: null,
      outcome: null,
      form: {
        target_magnitude: 15,
        zenithal_distance: 30,
        lines: [{
          filter: null,
          snr: null,
        }],
        seeing: 1.3,
        sky_brightness: 20,
        telescope_model: null,
        camera_model: null,
      }
    }
  },
  computed: {
    telescopeOptions() {
      if (!this.equipment) return []
      else {
        return this.equipment.telescope_models.map((tel) => ({
          value: tel.model,
          text: tel.model,
          disabled: false,
        }))
      }
    },
    cameraOptions() {
      if (!this.equipment) return []
      else {
        return this.equipment.camera_models.filter((camera) => !this.cameraDisabled(Object.keys(camera)[0])).map((camera) => ({
          value: Object.keys(camera)[0],
          text: Object.keys(camera)[0],
          disabled: false,
        }))
      }
    },
    filterOptions() {
      if (!this.equipment) return []
      else {
        return this.equipment.filters.map((filt) => ({
          value: filt,
          text: filt,
          disabled: this.filterDisabled(filt),
        }))
      }
    },
  },
  mounted() {
    this.fetchEquipment();
  },
  methods: {
    fetchEquipment() {
      this.loading = true;
      this.$apiRequest('get', 'equipment/').then((res) => {
        this.loading = false;
        this.equipment = res.data;
      })
    },
    filterDisabled(value) {
      if (this.form.telescope_model && this.form.camera_model) {
        return this.equipment.correspondence[this.form.telescope_model][this.form.camera_model].indexOf(value) == -1
      }
      if (this.form.telescope_model && !this.form.camera_model) {
        for (const [, filters] of Object.entries(this.equipment.correspondence[this.form.telescope_model])) {
          if (filters.indexOf(value) > 1) {
            return false
          }
        }
      }
      if (!this.form.telescope_model && this.form.camera_model) {
        for (const [, camsData] of Object.entries(this.equipment.correspondence)) {
          for (const [cam, filters] of Object.entries(camsData)) {
            if ((cam == this.form.camera_model) && (filters.indexOf(value) > -1)) {
              return false
            }
          }
        }
      }
      if (!this.form.telescope_model && !this.form.camera_model) {
        return false;
      }
      return true;
    },
    cameraDisabled(value) {
      if (this.form.telescope_model && this.form.lines[0].filter) {
        if (Object.keys(this.equipment.correspondence[this.form.telescope_model]).indexOf(value) == -1) {
          return true
        } else {
          return this.equipment.correspondence[this.form.telescope_model][value].indexOf(this.form.lines[0].filter) == -1
        }
      }
      if (this.form.telescope_model && !this.form.lines[0].filter) {
        return Object.keys(this.equipment.correspondence[this.form.telescope_model]).indexOf(value) == -1
      }
      if (!this.form.telescope_model && this.form.lines[0].filter) {
        for (const [, camsData] of Object.entries(this.equipment.correspondence)) {
          for (const [cam, filters] of Object.entries(camsData)) {
            if ((cam == value) && (filters.indexOf(this.form.lines[0].filter) > -1)) {
              return false
            }
          }
        }
      }
      if (!this.form.telescope_model && !this.form.lines[0].filter) {
        return false
      }
      return true;
    },
    telescopeDisabled(value) {
      if (this.form.camera_model && this.form.lines[0].filter) {
        if (Object.keys(this.equipment.correspondence[value]).indexOf(this.form.camera_model) == -1) {
          return true
        } else {
          return this.equipment.correspondence[value][this.form.camera_model].indexOf(this.form.lines[0].filter) == -1
        }
      }
      if (this.form.camera_model && !this.form.lines[0].filter) {
        return Object.keys(this.equipment.correspondence[value]).indexOf(this.form.camera_model) == -1
      }
      if (!this.form.camera_model && this.form.lines[0].filter) {
        for (const [, filters] of Object.entries(this.equipment.correspondence[value])) {
          if (filters.indexOf(this.form.lines[0].filter) > -1) {
            return false
          }
        }
      }
      if (!this.form.camera_model && !this.form.lines[0].filter) {
        return false
      }
      return true;
    },
    formValid() {
      let isValid = true;
      if ((this.form.target_magnitude == null) || (this.form.zenithal_distance == null)) {
        isValid = false;
      }
      if ((this.form.telescope_model == null) || (this.form.camera_model == null)) {
        isValid = false;
      } else {
        if (!this.form.lines) {
          isValid = false;
        } else {
          this.form.lines.forEach((line) => {
            if (!line.snr || !line.filter) {
              isValid = false
            }
          })
        }
      }
      return isValid
    },
    formatTime(seconds) {
      if (seconds > 3600) {
        return `${Math.floor(seconds / 3600)}h ${Math.floor((seconds % 3600) / 60)}min ${Math.floor(seconds % 60)}s`
      } else if (seconds > 60) {
        return `${Math.floor(seconds / 60)}min ${Math.floor(seconds % 60)}s`
      } else {
        return `${seconds.toFixed(2)}s`
      }
    },
  },
  watch: {
    form: {
      deep: true,
      immediate: true,
      handler() {
        var thisCallTime = this.lastFetchTime = Date.now()
        if (this.formValid()) {
          this.loading = true;
          this.$apiRequest('post', 'etc/', { data: this.form }, [409]).then((res) => {
            this.loading = false;
            if (this.lastFetchTime <= thisCallTime) {
              this.outcome = res.data;
              // this.form.telescope = res.data.telescope;
              // this.form.instrument = res.data.instrument;
            }
          }).catch(error => {
            this.$store.dispatch('alert/alert', {
                message: error.response.data.message,
                type: "error",
            });
            this.outcome = null;
            this.loading = false;
          });
        } else {
          if (this.lastFetchTime <= thisCallTime) {
            this.outcome = null;
          }
        }
      }
    },
    'form.lines': {
      deep: true,
      handler: function() {
        if (!!this.form.telescope && !this.telescopeIsValid(this.form.telescope)) {
          this.form.telescope = null;
          this.form.instrument = null;
        }
        if (!!this.form.instrument && !this.instrumentIsValid(this.form.instrument)) {
          this.form.instrument = null;
        }
      },
    },
    'form.telescope_model': function() {
      this.form.camera_model = null
    }
  }
}
</script>